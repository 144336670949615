import React from "react";
import { cn } from "@/app/_utils/ui";

// Extend the component props with React.HTMLAttributes<HTMLHeadingElement>
interface H2Props extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
}

export const H2 = ({ children, className, ...props }: H2Props) => {
  return (
    <h2
      {...props} // Spread the rest of the props to the h2 element
      className={cn("text-xl font-sans font-medium text-dark", className)}
    >
      {children}
    </h2>
  );
};
